//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
import { openAuthRequired, applyAuthAction } from '~/lib/auth'
export default {
  mixins: [global],
  props: {
    productId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    features: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      newComment: {
        text: ''
      },
      successMsg: '',
      opinionThanks: '',
      // Imagenes
      close: { alt: 'close', src: require('@/assets/img/icons/icon-close-white.svg'), width: 12, heigth: 7 },
      up: { alt: 'open', src: require('@/assets/img/icons/icon-chevron-up-primary.svg'), width: 12, heigth: 7 },
      left: { alt: 'left', src: require('@/assets/img/icons/icon-arrow-back.svg'), width: 12, heigth: 7 }
    }
  },
  mounted () {
    applyAuthAction.apply(this)
  },
  methods: {
    addOpinion () {
      openAuthRequired.apply(this, [{
        hash: '#opinions'
      }, {
        name: 'showModal'
      }, 'modal', 'productReview'])
    },
    showModal () {
      this.$bvModal.show('opinion-modal')
    },
    hideModal () {
      this.$bvModal.hide('opinion-modal')
    },
    submitOpinion () {
      const bodyFormData = new FormData()
      if (this.$refs.score) {
        bodyFormData.append('score', this.$refs.score._data.localVal)
      } else {
        for (let index = 0; index < this.features.length; index++) {
          const element = this.features[index].api
          bodyFormData.append('performances[' + element + ']', this.$refs[element][0]._data.localVal)
        }
      }
      bodyFormData.append('_content_id_1', parseInt(this.typeId, 10))
      bodyFormData.append('_content_id_2', parseInt(this.productId, 10))
      bodyFormData.append('text', this.newComment.text)
      try {
        this.$axios.post(this.$config.api + '/app/v1/users/reviews', bodyFormData, {
          headers: {
            Authorization: this.$auth.strategy.token.get()
          }
        }).then((resp) => {
          if (resp.status === 200) {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newOpinionButton.next')}`
            this.opinionThanks = `${this.$t('newOpinionButton.thanks')}`
          } else {
            this.hideModal()
            this.$refs['succes-modal'].show()
            this.successMsg = `${this.$t('newOpinionButton.try_again')}`
          }
        })
      } catch (error) {
        this.hideModal()
        this.$refs['succes-modal'].show()
        this.successMsg = `${this.$t('newOpinionButton.try_again')}`
      }
    }
  }
}
