//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import LazyHydrate from 'vue-lazy-hydration'
import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import global from '~/mixins/global.js'
import { openAuthRequired, applyAuthAction } from '~/lib/auth'
import * as cssFunctions from '~/mixins/css.functions.js'
import { VBPopover } from 'bootstrap-vue'
import { customPageView } from '~/lib/gtm'
import { filtersUrlIsIndexable } from '~/lib/seo'
import { countryData } from '~/lib/countries'

Vue.use(VueYoutube)
export default {
  directives: {
    'b-popover': VBPopover
  },
  components: {
    LazyHydrate,
    ModuleProductLeaf: () => import('~/components/ModuleProductLeaf.vue'),
    ModuleProductFeatured: () =>
      import('~/components/ModuleProductFeatured.vue'),
    ModuleProductSimilar: () => import('~/components/ModuleProductSimilar.vue')
  },

  mixins: [global],
  layout ({ $isAMP }) {
    return $isAMP ? 'default.amp' : 'default'
  },
  props: {
    prodTypeId: {
      type: [Number, String],
      default: () => 0
    },
    breadcrumb: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  async asyncData ({
    params,
    route,
    store,
    app,
    error,
    $axios,
    $config,
    $md5
  }) {
    try {
      const routeProps = route.matched[0].props.default
      const TypeId = routeProps.prodTypeId
      const pathMatch = params.pathMatch
      const productType = Object.values(projectData.contents.product_types).find(
        (p) => p.id === TypeId
      )
      if (productType && productType.filters) {
        productType.filters = productType.filters.map((filter) => {
          if (
            filter.share &&
            projectData.filters_share &&
            projectData.filters_share[filter.share]
          ) {
            filter = { ...projectData.filters_share[filter.share], ...filter }
          }
          return filter
        })
        productType.filters.filter((filter) => {
          return filter
        })
      }
      productType.paths.products =
        productType.paths.products ||
        projectData.contents.product_types.products.paths.products
      productType.paths.product_prices =
        productType.paths.product_prices ||
        projectData.contents.product_types.products.paths.product_prices
      productType.paths.products_all =
        productType.paths.products_all ||
        projectData.contents.product_types.products.paths.products_all
      productType.paths.comparator =
        productType.paths.comparator ||
        projectData.contents.product_types.products.paths.comparator
      const tagPath =
        productType &&
        (productType.paths.tag ||
        projectData.contents.product_types.products.paths.tag)

      let country = countryData(store.state.countries.countryProject)
      if ((country.id !== projectData.id) && (!productType.subprojects || !productType.subprojects.includes(country.id))) {
        country = countryData(projectData.id)
      }

      // Get Product JSON data
      let query = `${country.static}/json/products/${routeProps.prodTypeId}/${params.pathMatch}index.json`
      let code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        try {
          await store.dispatch('loadListsError', query)
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }
      const product = store.state.lists[code]

      // Zones & Heritages
      const ax = new AxiApi($config.api)
      let call = ''
      if (TypeId === '') {
        call = `${$config.static}/json/zones/products/zones.json`
      } else if (pathMatch === '') {
        call = `${$config.static}/json/zones/products/${TypeId}/zones.json`
      } else {
        call = `${$config.static}/json/products/${TypeId}/${pathMatch}zones.json`
      }
      let landingData
      try {
        landingData = await $axios.get(call)
      } catch (err) {
        landingData = {}
      }

      const productComments = JSON.parse(JSON.stringify(product.chats.lasts))
      productComments.forEach((chat, iChat) => {
        if (chat.all) {
          chat.all.forEach((comment, iComment) => {
            if (comment.wysiwyg && (comment.wysiwyg.length > 0)) {
              landingData.data.zones['Comment_' + iChat + '_' + iComment] = JSON.parse(JSON.stringify(comment.wysiwyg))
            }
          })
        }
      })

      await ax.createComponentData(landingData.data, store, country)

      // Get metas from kontan in product
      let metasCall = ''
      if (TypeId === '') {
        metasCall = `${$config.static}/json/products/index.json`
      } else if (pathMatch === '') {
        metasCall = `${$config.static}/json/products/${TypeId}/index.json`
      } else {
        metasCall = `${country.static}/json/products/${TypeId}/${pathMatch}index.json`
      }
      let metasLandingData
      try {
        metasLandingData = await $axios.get(metasCall)
        await ax.createComponentData(metasLandingData.data, store, country)
      } catch (err) {
        metasLandingData = {}
      }

      // Get Brand JSON data
      const brandQry = `${$config.static}/json/brands/${product.brand.id}.json`
      const brandCode = $md5(brandQry)
      if (
        typeof store.state.lists[brandCode] === 'undefined' ||
        Object.keys(store.state.lists[brandCode]).length === 0
      ) {
        try {
          await store.dispatch('loadListsError', brandQry)
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }
      const brand = store.state.lists[brandCode]
      // SizeChart
      const sizeChart =
        brand.size_chart && brand.size_chart !== '' ? brand.size_chart : ''

      // Eliminamos posibles emptys
      const pNews = product.news.filter((e) => Object.keys(e).length > 0)
      const pComparisons = product.comparisons.filter(
        (e) => Object.keys(e).length > 0
      )

      query = `${$config.api}/app/v1/products/comparison?_p=${$config.id}&products_type=${product.type_id}`
      code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        await store.dispatch('loadLists', query)
      }
      const comparisons = store.state.lists[code]

      // BreadCrumb
      const breadcrumbData = []
      if (productType) {
        breadcrumbData.push({
          text: productType.seo || productType.name,
          href: `/${productType.paths.home}/${productType.paths.products}/${productType.paths.products_all}/`
        })
        if (product?.brand?.id) {
          breadcrumbData.push({
            text: `${product.brand.name}`,
            href: `/${productType.paths.home}/${productType.paths.products}/${product.brand.id}/`
          })
        }
        if (product?.name) {
          const href = product?.path?.split('/')
          href.splice(0, 4)
          href.splice(-1, 1)
          breadcrumbData.push({
            text: product.name,
            href: `/${productType.paths.home}/${href.join('/')}/`
          })
        }
      }

      // Caracteristicas - Quitar tallas de caracteristicas*
      let noSizeFeatures = product.features.filter((f) => f.id !== 'size')
      for (let i = 0; i < noSizeFeatures.length; i++) {
        if (noSizeFeatures[i].id === 'weightm') {
          noSizeFeatures = noSizeFeatures.filter((f) => f.id !== 'weight')
        }
      }

      // Order Filters
      const filtersInfo = `${$config.api}/app/v1/prices/list?_p=${country.id}&products_type=${product.type_id}&prices=1`
      const filtersInfo1 = $md5(filtersInfo)
      if (
        typeof store.state.modules[filtersInfo1] === 'undefined' ||
        Object.keys(store.state.modules[filtersInfo1]).length === 0
      ) {
        await store.dispatch('loadModules', filtersInfo)
      }
      const filtersInfo1List = store.state.modules[filtersInfo1]

      let filters = ''
      let filtersSize = ''
      let filtersSizeArray = []
      const stateFilters =
        store.state.filters.list.prices?.[productType.id]?.global
      if (stateFilters && filtersInfo1List) {
        filtersInfo1List.filters.url.forEach((filter) => {
          // Se recorre: store, brand, gender,...
          const stateFilter = stateFilters[filter.id]
          if (typeof stateFilter !== 'undefined' && filter.id !== 'brand') {
            const valConfig = []
            let valArr = Object.keys(stateFilter)
            if (filter.id === 'size') {
              valArr = valArr.sort((a, b) => a - b)
            }
            valArr.forEach((v) => {
              if (filter.values) {
                const valorConfig = filter.values.filter(
                  (val) => v.toString() === val.id.toString()
                )[0]
                valConfig.push(valorConfig.id)
              }
            })
            if (filter.id === 'size') {
              filtersSizeArray = valConfig
              filtersSize = valConfig.join(', ')
            }
            filters += '/' + filter.id + '-' + valConfig.join(',')
          }
        })
      }

      let hasPrice = true
      let highlight = ''
      let highlightStore = false
      const extraModulesTop1 = `${$config.api}/app/v1/prices/list?_p=${country.id}&products_type=${product.type_id}&prices=1&filters=${filters}&products=${product.content_id}`
      const extraModulesTopCode1 = $md5(extraModulesTop1)

      if (
        typeof store.state.modules[extraModulesTopCode1] === 'undefined' ||
        Object.keys(store.state.modules[extraModulesTopCode1]).length === 0
      ) {
        await store.dispatch('loadModules', extraModulesTop1)
      }
      let extraModulesTopCode1List = store.state.modules[extraModulesTopCode1]
      // Si no tiene precios [Módulo]: Productos de [la marca] relacionados con esta búsqueda
      if (extraModulesTopCode1List.contents.length === 0) {
        hasPrice = false
        // Obtenemos los productos de [la marca]
        const relatedProducts = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${product.type_id}&prices=1&filters=/brand-${product.brand.id}&contents=8&?order=date_desc`
        const relatedProductsCode = $md5(relatedProducts)

        if (
          typeof store.state.modules[relatedProductsCode] === 'undefined' ||
          Object.keys(store.state.modules[relatedProductsCode]).length === 0
        ) {
          await store.dispatch('loadModules', relatedProducts)
        }
        extraModulesTopCode1List = store.state.modules[relatedProductsCode]
      } else {
        highlight = extraModulesTopCode1List.highlight?.discount_price?.price
          ? extraModulesTopCode1List.highlight.discount_price.price
          : extraModulesTopCode1List.highlight.price
        highlightStore = extraModulesTopCode1List.highlight?.store
      }
      const gender = extraModulesTopCode1List.filters.view.find(
        (element) => element.id === 'gender'
      )
      const activeGender = gender
        ? gender.values.filter((element) => element.active === 1)
        : []

      let isActiveFilter = false
      let count = 0
      if (
        stateFilters &&
        filtersInfo1List &&
        extraModulesTopCode1List.contents.length > 0
      ) {
        extraModulesTopCode1List.filters.url.forEach((filter) => {
          const selectedFilters = stateFilters[filter.id]
          if (
            typeof selectedFilters !== 'undefined' &&
            selectedFilters.length > 0
          ) {
            filter.values.forEach((value) => {
              if (selectedFilters[value.id]) {
                isActiveFilter = true
                count++
              }
            })
          }
        })
      }

      // Get products/list API data
      const extraModulesBottom1 = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${product.type_id}&filters=/brand-${product.brand.id}&contents=4`
      const extraModulesBottomCode1 = $md5(extraModulesBottom1)
      if (
        typeof store.state.lists[extraModulesBottomCode1] === 'undefined' ||
        Object.keys(store.state.lists[extraModulesBottomCode1]).length === 0
      ) {
        await store.dispatch('loadLists', extraModulesBottom1)
      }
      const extraModulesBottomCode1List =
        store.state.lists[extraModulesBottomCode1]

      const extraModulesBottom2 = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${product.type_id}&filters=/brand-${product.brand.id}&order=date${product.content_id}&direction=asc&contents=8`
      const extraModulesBottomCode2 = $md5(extraModulesBottom2)
      if (
        typeof store.state.lists[extraModulesBottomCode2] === 'undefined' ||
        Object.keys(store.state.lists[extraModulesBottomCode2]).length === 0
      ) {
        await store.dispatch('loadLists', extraModulesBottom2)
      }
      const extraModulesBottomCode2List =
        store.state.lists[extraModulesBottomCode2]

      if (extraModulesBottomCode2List.contents.length < 8) {
        const extraModulesBottom3 = `${$config.api}/app/v1/products/list?_p=${$config.id}&products_type=${product.type_id}&filters=/brand-${product.brand.id}&order=date${product.content_id}&direction=desc&contents=${8 - extraModulesBottomCode2List.contents.length}`
        const extraModulesBottomCode3 = $md5(extraModulesBottom3)
        if (
          typeof store.state.lists[extraModulesBottomCode3] === 'undefined' ||
          Object.keys(store.state.lists[extraModulesBottomCode3]).length === 0
        ) {
          await store.dispatch('loadLists', extraModulesBottom3)
        }
        const extraModulesBottomCode3List =
          store.state.lists[extraModulesBottomCode3]

        extraModulesBottomCode3List.contents.forEach((content) => {
          extraModulesBottomCode2List.contents.push(content)
        })
      }

      const extraModulesTop = []
      if (extraModulesTopCode1List.contents.length > 0 && hasPrice) {
        extraModulesTop.push({
          template: 'ModuleProductLeaf',
          data: extraModulesTopCode1List.contents.slice(0, 8),
          elements: {
            brand: true,
            name: false,
            photo: true,
            price: true,
            pvp: false,
            product: { name: product.name, brand: product.brand.name }
          },
          generic: {
            header: {},
            footer: [
              {
                text: app.i18n.t('product_page.see_more_prices')
                  ? app.i18n.t('product_page.see_more_prices')
                  : '',
                url: `${productType.paths.product_prices}/${productType.paths.prices_all}/`
              }
            ]
          },
          type: 'PriceCard',
          org: 'Destacados',
          test: extraModulesTopCode1List.test,
          country
        })
      } else if (extraModulesTopCode1List.contents.length > 0) {
        extraModulesTop.push({
          template: 'ModuleProductFeatured',
          data: extraModulesTopCode1List.contents,
          elements: {
            brand: true,
            name: true,
            photo: true,
            price: true
          },
          generic: {
            header: {
              text_module: `${app.i18n.t('product_page.products_of')} ${
                product.brand.name
              } ${app.i18n.t('product_page.related_to')}`
            }
          },
          type: 'ProductCard',
          org: 'Destacados',
          test: extraModulesTopCode1List.test
        })
      }

      const extraModulesBottom = []
      if (extraModulesBottomCode1List.contents.length > 0) {
        extraModulesBottom.push({
          template: 'ModuleProductFeatured',
          data: extraModulesBottomCode1List.contents,
          elements: {
            brand: true,
            name: true,
            photo: true,
            price: true
          },
          generic: {
            header: {
              text_module: `${app.i18n.t('product_page.also_from')} ${
                product.brand.name
              }`
            },
            footer: (extraModulesBottomCode2List.contents.length > 0)
              ? []
              : [
                  {
                    text: `${app.i18n.t('product_page.show_more')} ${
                  productType.name
                } ${app.i18n.t('product_page.of')} ${product.brand.name}`,
                    url: `/${productType.paths.home}/${productType.paths.products}/${product.brand.id}/`
                  }
                ]
          },
          type: 'ProductCard'
        })
      }
      if (extraModulesBottomCode2List.contents.length > 0) {
        extraModulesBottom.push({
          template: 'ModuleProductFeatured',
          data: extraModulesBottomCode2List.contents,
          elements: {
            brand: true,
            name: true,
            photo: true,
            price: true
          },
          generic: {
            header: (extraModulesBottomCode1List.contents.length > 0)
              ? {}
              : {
                  text_module: `${app.i18n.t('product_page.also_from')} ${
                product.brand.name
              }`
                },
            footer: [
              {
                text: `${app.i18n.t('product_page.show_more')} ${
                  productType.name
                } ${app.i18n.t('product_page.of')} ${product.brand.name}`,
                url: `/${productType.paths.home}/${productType.paths.products}/${product.brand.id}/`
              }
            ]
          },
          type: 'ProductCard'
        })
      }

      let video = false

      if (app.$isAMP) {
        if (ax.get().GlobalData.zones?.Text?.length) {
          ax.get().GlobalData.zones.Text.some((module) => {
            if (module.type === 'video') {
              video = module.content.info
            }
            return video
          })
        }
      }

      // Structured Data and Breadcrumb
      let reviewBody = ''
      if (ax.get().GlobalData.zones && ax.get().GlobalData.zones.Review_0) {
        if (ax.get().GlobalData.zones.Review_0.length > 0) {
          if (
            typeof ax.get().GlobalData.zones.Review_0[0].content.info === 'string'
          ) {
            reviewBody = ax
              .get()
              .GlobalData.zones.Review_0[0].content.info.replace(/<strong>/g, '')
              .replace(/<\/strong>/g, '')
              .replace(/<a.*<\/a>/, '')
          }

          if (app.$isAMP && !video) {
            ax.get().GlobalData.zones.Review_0.some((module) => {
              if (module.type === 'video') {
                video = module.content.info
              }
              return video
            })
          }
        }
        if (ax.get().GlobalData.zones.Review_0.length > 1) {
          if (
            typeof ax.get().GlobalData.zones.Review_0[1].content.info === 'string'
          ) {
            reviewBody += ax
              .get()
              .GlobalData.zones.Review_0[1].content.info.replace(/<strong>/g, '')
              .replace(/<\/strong>/g, '')
              .replace(/<a.*<\/a>/, '')
          }
        }
      }

      const structuredData = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: `${product.brand.name} ${product.name}`,
        image: `${product.photo.medium.src}`,
        offers: [],
        description: `${productType.metas.product.description.replace(
          /{product}/g,
          ' ' + product.brand.name + ' ' + product.name + ' '
        )}`,
        brand: {
          '@type': 'Brand',
          name: `${product.brand.name}`
        },
        url: `${$config.domain}${route.path}`
      }

      if (extraModulesTopCode1List.contents.length > 0) {
        // Offers
        extraModulesTopCode1List.contents.forEach((price) => {
          // Si tiene precios
          if (price.store && price.type !== 'AdvertisingProductsCard') {
            structuredData.offers.push({
              '@type': 'Offer',
              price: `${price.price}`,
              priceCurrency: `${app.i18n.t('product_page.money_acronim')}`,
              url: `${price.link.url}`,
              image: `${price.photo}`,
              availability: 'https://schema.org/InStock'
            })
          }
        })
      } else if (product.price.real) {
        structuredData.offers.push({
          type: 'Offer',
          price: `${product.price.real}`,
          priceCurrency: `${app.i18n.t('product_page.money_acronim')}`,
          image: `${product.photo.medium.src}`
        })
      }

      if (product.mpn !== '') {
        structuredData.mpn = product.mpn
        structuredData.sku = product.mpn
      }

      if (product.opinions && product.opinions.total > 0) {
        // si tiene opinión
        structuredData.aggregateRating = {
          '@type': 'AggregateRating',
          bestRating: 5,
          worstRating: 0,
          ratingValue: product.opinions.score / 2,
          reviewCount: product.opinions.total
        }
      }
      if (product.reviews && (product.reviews.length > 0) && product.reviews[0].score) {
        // Si tiene review
        structuredData.review = {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: product.reviews[0].author.name
          },
          datePublished: product.review?.date,
          headline: product.reviews[0].title,
          reviewBody: reviewBody !== '' ? reviewBody : product.reviews[0].title,
          positiveNotes:
            product.reviews[0].prosandcons?.pro &&
            product.reviews[0].prosandcons.pro.map(function (pro, index) {
              return {
                '@type': 'ListItem',
                position: index + 1,
                name: pro
              }
            }),
          negativeNotes:
            product.reviews[0].prosandcons?.con &&
            product.reviews[0].prosandcons.con.map(function (con, index) {
              return {
                '@type': 'ListItem',
                position: index + 1,
                name: con
              }
            })
        }
        structuredData.reviewRating = {
          '@type': 'Rating',
          ratingValue: product.reviews[0].score,
          bestRating: 10,
          worstRating: 1
        }
        structuredData.aggregateRating = {
          '@type': 'AggregateRating',
          bestRating: 10,
          ratingValue: product.review.score,
          reviewCount: 1
        }
      }

      const structuredDataBreadCrumb = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: []
      }
      if (breadcrumbData.length > 0) {
        breadcrumbData.forEach(function (element, index) {
          if (element.active) {
            structuredDataBreadCrumb.itemListElement.push({
              '@type': 'ListItem',
              position: index + 1,
              name: element.text
            })
          } else {
            structuredDataBreadCrumb.itemListElement.push({
              '@type': 'ListItem',
              position: index + 1,
              name: element.text,
              item: `${$config.domain}${element.href}`
            })
          }
        })
      }

      // Metas & Head
      let ampPath = route.path
      if (app.$isAMP) {
        ampPath = route.path.replace('index.amp.html', '')
      }

      const canonical =
        metasLandingData.data.metas.canonical !== ''
          ? metasLandingData.data.metas.canonical.match(/^https?:\/\//)
            ? metasLandingData.data.metas.canonical
            : `${$config.domain}${metasLandingData.data.metas.canonical}`
          : false

      const title = `${product.brand.name} ${product.name}, ${app.i18n.t('product_page.features_and_opinions')} ${product.price.lower?.custom ? ' | ' + app.i18n.t('product_page.from') + ' ' + global.methods.formatPrice(product.price.lower.custom, country) : ''} | ${projectData.name}`

      const head = {
        title,
        meta: [
          {
            hid: 'title',
            name: 'title',
            content: title
          },
          {
            hid: 'description',
            name: 'description',
            content: `${productType.metas.product.description.replace(
              /{product}/g,
              ' ' + product.brand.name + ' ' + product.name + ' '
            )}`
          },
          {
            property: 'og:url',
            content: `${$config.domain}${ampPath}`
          },
          {
            property: 'og:title',
            content: title
          },
          {
            property: 'og:description',
            content: `${productType.metas.product.description.replace(
              /{product}/g,
              ' ' + product.brand.name + ' ' + product.name + ' '
            )}`
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: `${$config.locale}`
          }
        ],
        link: [],
        script: [
          { type: 'application/ld+json', json: structuredDataBreadCrumb },
          { type: 'application/ld+json', json: structuredData }
        ]
      }

      if (canonical) {
        head.link.push({
          rel: 'canonical',
          href: canonical
        })
      } else {
        head.link.push({
          rel: 'canonical',
          href: `${$config.domain}${ampPath}`
        })
      }

      if (Object.keys(route.query).length > 0) {
        head.meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        })
      }

      if (!app.$isAMP && projectData.amp) {
        head.link.push({
          hid: 'amphtml',
          rel: 'amphtml',
          href: `${$config.domain}${route.fullPath}index.amp.html`
        })
      }

      if (product?.versions?.length > 0) {
        product.versions.forEach((version) => {
          if (version.lang === 'es') {
            head.link.push({
              rel: 'alternate',
              hreflang: version.lang,
              href: version.url + (app.$isAMP ? 'index.amp.html' : '')
            })
            head.link.push({
              rel: 'alternate',
              hreflang: 'x-default',
              href: version.url + (app.$isAMP ? 'index.amp.html' : '')
            })
          } else {
            head.link.push({
              rel: 'alternate',
              hreflang: version.lang,
              href: version.url + (app.$isAMP ? 'index.amp.html' : '')
            })
          }
        })
      }

      if (product?.photo?.medium?.src !== '') {
        head.meta.push({
          property: 'og:image',
          content: `${product.photo.medium.src}`
        })
      }

      if (product?.photo?.small?.src) {
        head.link.push({
          rel: 'preload',
          as: 'image',
          href: `${product.photo.small.src}`
        })
      }

      if (product?.photo?.medium?.src) {
        head.link.push({
          rel: 'preload',
          as: 'image',
          href: `${product.photo.medium.src}`
        })
      }

      const zones = ax.get()

      productComments.forEach((chat, iChat) => {
        if (chat.all) {
          chat.all.forEach((comment, iComment) => {
            if (zones?.GlobalData?.zones['Comment_' + iChat + '_' + iComment] && (zones.GlobalData.zones['Comment_' + iChat + '_' + iComment].length > 0)) {
              productComments[iChat].all[iComment].wysiwyg = zones.GlobalData.zones['Comment_' + iChat + '_' + iComment]
            }
          })
        }
      })

      let inquiryModal = false
      if (zones.GlobalData?.zones) {
        Object.values(zones.GlobalData?.zones).forEach((zone) => {
          zone.forEach((item) => {
            if (item.template && item.template === 'InquiryModal') {
              inquiryModal = true
              return false
            }
          })
          if (inquiryModal) {
            return false
          }
        })
      }

      if (!app.$isAMP) {
        head.style = await cssFunctions.criticals(route.path, 'product_page', {
          ABC_0: zones.GlobalData?.zones?.ABC_0 || 0,
          extraModulesTop: extraModulesTop.length ? 1 : 0,
          gallery: product.photos.length ? 1 : 0,
          video: product.video ? 1 : 0,
          score: product.score ? 1 : 0,
          price: product.prices.total ? 1 : 0
        })
      }

      // Antiguedad
      const today = new Date()
      const prodDate = new Date(Date.parse(product.date))
      const timeDiff = today.getTime() - prodDate.getTime()
      const antiqueness = Math.ceil(timeDiff / (1000 * 3600 * 24))

      productType.users = productType.users || projectData.users
      productType.services = productType.services || projectData.services

      const pStamps = (product.stamps && [...product.stamps]) || []
      if (product.rscore?.stamp) {
        pStamps.unshift(product.rscore.stamp)
      }

      const feedback = {
        product: {},
        user: '',
        response: false
      }

      const response = await $axios.get(
        `${$config.api}/app/v1/products/feedback/${product.type_id}/${product.content_id}?_p=${$config.id}`
      )
      if (response?.data) {
        feedback.product = response.data
      }

      let dateFormatMonth = ''
      if (product.need_know?.fecha) { dateFormatMonth = global.methods.formatDate(product.need_know?.fecha, { month: 'long', year: 'numeric' }) }

      let reviews = []
      if (product.opinions?.contents) {
        product.opinions.contents.forEach((review) => {
          reviews.push({ ...review })
        })

        reviews = reviews.map((review) => {
          review.id = (review.id && parseInt(review.id)) || 0
          review.likes = {
            total: 0,
            user: false,
            response: false
          }
          return review
        })

        reviews = reviews.sort((a, b) => {
          if (a.user.experto === '1' && b.user.experto !== '1') {
            return -1
          } else if (a.user.experto !== '1' && b.user.experto === '1') {
            return 1
          } else {
            return 0
          }
        })

        if (reviews.length) {
          const response = await $axios.get(
            `${$config.api}/app/v1/products/reviewsLikes/${product.type_id}/${product.content_id}?_p=${$config.id}`
          )
          if (response?.data) {
            reviews = reviews.map((review) => {
              const reviewLikes = response.data.find(
                (reviewLikes) => review.id === reviewLikes.id
              )
              if (reviewLikes) {
                review.likes.total = reviewLikes.likes
              }
              return review
            })
          }
        }
      }

      return {
        productType,
        breadcrumbData,
        noSizeFeatures,
        location: pathMatch,
        product,
        pComparisons,
        pNews,
        pStamps,
        comparisons,
        prices: extraModulesTopCode1List,
        testAB: extraModulesTopCode1List.test,
        tagPath,
        hasPrice,
        highlight,
        highlightStore,
        sizeChart,
        urlFilters: extraModulesTopCode1List.filters.url,
        zones,
        extraModulesTop,
        extraModulesBottom,
        head,
        headerGallery: new Array(product.photo).concat(product.photos),
        bodyGallery: product.photos.filter((e) => e.large !== ''),
        filters,
        isActiveFilter,
        count,
        activeGender,
        antiqueness,
        feedback,
        reviews,
        inquiryModal,
        productComments,
        country,
        filtersSize,
        filtersSizeArray,
        dateFormatMonth
      }
    } catch (err) {
      console.log(err)
      return error({ statusCode: 404, message: err.message })
    }
  },
  data () {
    return {
      // Carousel
      slide: 0,
      slideHeader: 0,
      slideRivals: 0,
      slideSelected: 0,
      // iconBars: require('~/assets/img/icons/icon-bars.svg'),
      iconArrowsRange: require('~/assets/img/icons/icon-arrows-range.svg'),
      iconChevron: require('~/assets/img/icons/icon-chevron-down-grey.svg'),
      iconClose: require('~/assets/img/icons/icon-close.svg'),
      filterActive: false,
      from: false,
      activeSection: 'description',
      comparisons: [],
      selectedFilter: {},
      bestPrice: {},
      elements: {
        brand: true,
        name: true,
        photo: true
      },
      // Comparador
      compModalShow: false,
      // imagenes
      iconavatarIMG: {
        alt: 'avatar',
        size: '2rem',
        src: require('@/assets/img/icons/icon-avatar.svg')
      },
      chevrondowngrey: {
        alt: 'close',
        src: require('@/assets/img/icons/icon-chevron-down-grey.svg'),
        width: 12,
        height: 17
      },
      chevronupprimary: {
        alt: 'open',
        src: require('@/assets/img/icons/icon-chevron-up-primary.svg'),
        width: 12,
        height: 17
      },
      selectedf: null,
      visible: true,
      projectID: projectData.id,
      scroll: false,
      filters: []
    }
  },
  head () {
    return this.head
  },
  computed: {
    player () {
      return this.$refs.video.player
    },
    sagaProductText () {
      return this.$t('ProductHeaderButtons.version', { version: this.product.saga.product.title })
    }
  },
  async beforeMount () {
    if (this.$auth.loggedIn) {
      const responseFeedback = await this.$axios.get(
        `${this.$config.api}/app/v1/users/feedback/${this.product.type_id}/${this.product.content_id}`,
        { withCredentials: true }
      )
      if (responseFeedback.data) {
        this.feedback.product = responseFeedback.data.states
        this.feedback.user = responseFeedback.data.state
      }

      if (this.reviews.length) {
        const responseReviewsLikes = await this.$axios.get(
          `${this.$config.api}/app/v1/users/reviewsLikes/${this.product.type_id}/${this.product.content_id}`,
          { withCredentials: true }
        )
        if (responseReviewsLikes.data) {
          this.reviews = this.reviews.map((review) => {
            const reviewLikes = responseReviewsLikes.data.find(
              (reviewLikes) => {
                return review.id === reviewLikes.id
              }
            )
            if (reviewLikes) {
              review.likes.total = reviewLikes.likes
              review.likes.user = reviewLikes.user === 1
              review.likes.response =
                reviewLikes.user === 1 &&
                this.$t('ProductFeedback.response_ok')
            }
            return review
          })
        }
      }
    }
    if (this.prices?.filters?.view) {
      this.filters = JSON.parse(JSON.stringify(this.prices.filters.view))

      this.filters.map((filter) => {
        const filterMapped = filter

        if (filterMapped.values) {
          filterMapped.values.map((value) => {
            const valueMapped = value

            valueMapped.finalUrl = this.create_filter_url(
              filter.id,
              value.id,
              this.prices.filters.url,
              this.productType,
              'product_prices',
              this.location,
              this.$route.query
            )

            const customFiltersActive = JSON.parse(JSON.stringify(this.prices.filters.active || {}))

            if (customFiltersActive[filter.id]) {
              Object.keys(customFiltersActive).forEach((filterID) => {
                if (filterID === filter.id) {
                  if (customFiltersActive[filterID].includes(value.id)) {
                    customFiltersActive[filterID] = customFiltersActive[filterID].filter((filterValue) => {
                      return filterValue !== value.id
                    })
                  } else {
                    customFiltersActive[filterID].push(value.id)
                  }
                }
              })
            } else {
              customFiltersActive[filter.id] = [value.id]
            }

            valueMapped.nofollow = (Object.keys(this.$route.query).length > 0) || !filtersUrlIsIndexable(customFiltersActive, this.productType, 'product_prices')

            return valueMapped
          })
        }

        return filterMapped
      })
    }
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
    if (this.country.id !== projectData.id) {
      this.$gtm && this.$gtm.push({ event: 'IntView', country: this.country.iso, location: 'Page' })
    }
    document.body.classList.add('with-bottom-bar')

    const hash = this.$route?.hash ?? ''
    if (hash) {
      this.changeSection(hash.replace('#', ''), false)
    }

    this.$store.dispatch('setVersionsForChangeLang', this.product.versions)
    applyAuthAction.apply(this)
  },
  beforeDestroy () {
    this.$store.dispatch('setVersionsForChangeLang', null)
    document.body.classList.remove('with-bottom-bar')
  },
  methods: {
    onSlideEnd (slide) {
      this.slideSelected = slide
    },
    changeSection (refName, route = true) {
      this.activeSection = refName
      if (route) {
        this.$router.push({
          hash: `#${refName}`
        })
      }
    },
    async playVideo () {
      if (this.$device.isMobileOrTablet) {
        const player = await this.player
        const iframe = window.document.getElementById('video-iframe')
        const req =
          iframe &&
          (iframe.requestFullscreen ||
            iframe.webkitRequestFullscreen ||
            iframe.mozRequestFullScreen ||
            iframe.msRequestFullscreen)
        if (req && player && iframe) {
          player.playVideo()
          req.call(iframe)
          return
        }
      }

      this.changeSection('video')
    },
    selectSlide (index) {
      this.$refs.productCarouselHeader.setSlide(index)
      this.slideSelected = index
    },
    getShownQuestions (total) {
      if (total >= 5) {
        return 5
      } else {
        return total
      }
    },
    sendFeedback (state) {
      openAuthRequired.apply(this, [
        {
          hash: '#feedback'
        },
        {
          name: 'updateFeedback',
          params: [state]
        },
        'modal',
        'productFeedback'
      ])
    },
    updateFeedback (state) {
      this.feedback.response = this.$t('ProductFeedback.response_wait')
      const bodyFormData = new FormData()
      bodyFormData.append('_p', this.$config.id)
      bodyFormData.append('state', state)
      this.$axios
        .post(
          `${this.$config.api}/app/v1/users/feedback/${this.product.type_id}/${this.product.content_id}`,
          bodyFormData,
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data) {
            this.feedback.product = response.data.states
            this.feedback.user = response.data.state
            this.feedback.response = this.$t('ProductFeedback.response_ok')
          } else {
            this.feedback.response = this.$t('ProductFeedback.response_ko')
          }
        })
        .catch(() => {
          this.feedback.response = this.$t('ProductFeedback.response_ko')
        })
    },
    sendReviewLike (id) {
      openAuthRequired.apply(this, [
        {
          hash: '#opinions'
        },
        {
          name: 'updateReviewLike',
          params: [id]
        },
        'modal',
        'productReviewLike'
      ])
    },
    updateReviewLike (id) {
      const review = this.reviews.find((review) => review.id === id)
      if (review) {
        review.likes.response = this.$t('ProductFeedback.response_wait')
        const bodyFormData = new FormData()
        bodyFormData.append('_p', this.$config.id)
        this.$axios
          .post(
            `${this.$config.api}/app/v1/users/reviewsLikes/${id}`,
            bodyFormData,
            { withCredentials: true }
          )
          .then((response) => {
            if (response.data) {
              review.likes.total = response.data.likes
              review.likes.user = true
              review.likes.response = this.$t('ProductFeedback.response_ok')
            } else {
              review.likes.response = this.$t('ProductFeedback.response_ko')
            }
          })
          .catch(() => {
            review.likes.response = this.$t('ProductFeedback.response_ko')
          })
      }
    }
  }
}
