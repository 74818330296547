//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration'
import projectData from '~/static/config/project.json'
import AxiApi from '~/lib/axi'
import global from '~/mixins/global.js'
import * as textFunctions from '~/mixins/text.functions.js'
import { VBPopover } from 'bootstrap-vue'
import * as cssFunctions from '~/mixins/css.functions.js'
import { customPageView } from '~/lib/gtm'
import { filtersUrlIsIndexable } from '~/lib/seo'
import { countryData } from '~/lib/countries'

export default {
  directives: {
    'b-popover': VBPopover
  },
  components: {
    LazyHydrate,
    AdvertisingProductsCard: () =>
      import('~/components/AdvertisingProductsCard.vue'),
    PriceCard: () => import('~/components/PriceCard.vue'),
    ModuleTexto: () => import('~/components/ModuleTexto.vue')
  },
  mixins: [global],
  layout ({ $isAMP }) {
    return $isAMP ? 'default.amp' : 'default'
  },
  middleware: ['Filters'],
  props: {
    prodTypeId: {
      type: [Number, String],
      default: () => 0
    },
    breadcrumb: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  async asyncData ({ params, route, app, store, $axios, $config, $md5, error }) {
    try {
      // Params & Props
      const routeProps = route.matched[0].props.default
      const TypeId = routeProps.prodTypeId
      let productType = Object.values(projectData.contents.product_types).find(
        (p) => p.id === TypeId
      )
      if (!productType) {
        productType = Object.values(
          projectData.contents.product_types_marketplaces
        ).find((pt) => pt.id === TypeId)
        if (productType) {
          productType.marketplace = true
        } else {
          productType = Object.values(
            projectData.contents.product_types_marketplaces
          ).find((pt) => pt.id === '0')
        }
      } else {
        productType.marketplace = false
      }
      if (productType && productType.filters) {
        productType.filters = productType.filters.map((filter) => {
          if (
            filter.share &&
            projectData.filters_share &&
            projectData.filters_share[filter.share]
          ) {
            filter = { ...projectData.filters_share[filter.share], ...filter }
          }
          return filter
        })
        productType.filters.filter((filter) => {
          return filter
        })
      }
      productType.paths.products =
        productType.paths.products ||
        projectData.contents.product_types.products.paths.products
      productType.paths.products_all =
        productType.paths.products_all ||
        projectData.contents.product_types.products.paths.products_all
      productType.paths.product_prices =
        productType.paths.product_prices ||
        projectData.contents.product_types.products.paths.product_prices
      productType.paths.prices_all =
        productType.paths.prices_all ||
        projectData.contents.product_types.products.paths.prices_all
      const pathMatch = params.pathMatch

      let country = countryData(store.state.countries.countryProject)
      if ((country.id !== projectData.id) && (!productType.subprojects || !productType.subprojects.includes(country.id))) {
        country = countryData(projectData.id)
      }

      // Zones & Heritages

      const ax = new AxiApi($config.api)
      const zones = await $axios.get(
        `${$config.static}/json/products/${routeProps.prodTypeId}/${params.pathMatch}/zones.json`
      )
      await ax.createComponentData(zones.data, store)

      // Get metas from kontan in product
      let metasCall = ''
      if (TypeId === '') {
        metasCall = `${$config.static}/json/products/index.json`
      } else if (pathMatch === '') {
        metasCall = `${$config.static}/json/products/${routeProps.prodTypeId}/index.json`
      } else {
        metasCall = `${country.static}/json/products/${routeProps.prodTypeId}/${params.pathMatch}/index.json`
      }
      let metasLandingData
      try {
        metasLandingData = await $axios.get(metasCall)
        await ax.createComponentData(metasLandingData.data, store)
      } catch (err) {
        metasLandingData = {}
      }

      // Get JSON data
      const location = params.pathMatch

      let query = `${country.static}/json/products/${routeProps.prodTypeId}/${params.pathMatch}/index.json`
      let code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        try {
          await store.dispatch('loadListsError', query)
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }
      const product = store.state.lists[code]

      // Get Brand JSON data
      const brandQry = `${$config.static}/json/brands/${product.brand.id}.json`
      const brandCode = $md5(brandQry)
      if (
        typeof store.state.lists[brandCode] === 'undefined' ||
        Object.keys(store.state.lists[brandCode]).length === 0
      ) {
        try {
          await store.dispatch('loadListsError', brandQry)
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }
      const brand = store.state.lists[brandCode]

      // SizeChart
      const sizeChart =
        brand.size_chart && brand.size_chart !== '' ? brand.size_chart : ''

      // Eliminamos posibles emptys
      const pNews = product.news.filter((e) => Object.keys(e).length > 0)

      // Breadcrumb
      const breadcrumbData = []
      if (productType) {
        breadcrumbData.push({
          text: productType.seo || productType.name,
          href: `/${productType.paths.home}/${productType.paths.products}/${productType.paths.products_all}/`
        })
        if (product?.brand?.id !== '') {
          breadcrumbData.push({
            text: `${product.brand.name}`,
            href: `/${productType.paths.home}/${productType.paths.products}/${product.brand.id}/`
          })
        }

        const href = product?.path?.split('/')
        href.splice(0, 4)
        href.splice(-1, 1)

        if (product?.name !== '') {
          breadcrumbData.push({
            text: product.name,
            href: `/${productType.paths.home}/${href.join('/')}/`
          })
        }
        breadcrumbData.push({
          text: app.i18n.t('product_prices.prices')
        })
      }

      // Sacar precios y filtros
      let filtersSize = ''
      let filtersSizeArray = []
      let page = 1
      const filters = {}
      const filtersSEO = {}
      let filters2api = ''
      const filters2seo = {}
      let actives = 0
      let filterActive = false
      let from = false
      const selectedFilter = {}
      let bestPrice = {}
      const selectedBrand = []
      const selectedStore = []
      const pPricesList = []
      let minPrice = 0
      let maxPrice = 0
      if (params.tienda) {
        filters.store = [params.tienda]
        const pt = params.tienda.split(',')
        pt.forEach((el) => {
          if (
            el !== '' &&
            store.state.stores.filter((b) => {
              return b.id === el
            })[0] !== undefined
          ) {
            selectedStore.push(
              store.state.stores.filter((b) => {
                return b.id === el
              })[0]
            )
          }
        })
      }
      if (params[1] !== '') {
        let filtersUrl = params[1]
        if (filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)) {
          const pathMatches = filtersUrl.match(/^(.*?\/)?([0-9]+)\/?$/)
          page = pathMatches[2]
          filtersUrl = pathMatches[1] !== undefined ? pathMatches[1] : ''
        }

        try {
          filtersUrl.split('/').forEach((element) => {
            if (element !== '') {
              const matches = element.match(/^([^-]+)-(.*?)$/)
              const filtroId = matches[1]
              let filtroValues = matches[2].split(',')
              const filtroValuesSEO = []
              const filtroConfig = productType.filters.find((f) => {
                return (f.url || f.name) === filtroId
              })
              if (filtroConfig?.values) {
                const newFiltroValues = []
                filtroValues.forEach((v) => {
                  newFiltroValues.push(
                    filtroConfig.values.find((fv) => (fv.url || fv.name) === v)
                      .id
                  )
                  const filtroValue = filtroConfig.values.find(
                    (fv) => (fv.url || fv.name) === v
                  )
                  if (filtroValue) {
                    filtroValuesSEO.push(filtroValue.seo || filtroValue.name)
                  }
                })
                filtroValues = newFiltroValues
              }
              // nueva funcionalidad mejor precio
              from = false
              bestPrice = {}
              const pricesFeatures = product.features_prices.features[0]
              if (pricesFeatures?.length) {
                for (
                  let index = 0;
                  index < pricesFeatures.features.length;
                  index++
                ) {
                  const element = pricesFeatures.features[index]
                  if (
                    element === filtroConfig.id &&
                    filtroValues.length === 1
                  ) {
                    if (selectedFilter[filtroId] !== filtroValues) {
                      selectedFilter[filtroConfig.id] = filtroValues
                    } else {
                      selectedFilter[filtroConfig.id] = ''
                    }
                    filterActive = !filterActive
                  }
                }
                if (pricesFeatures.prices.length > 0) {
                  for (
                    let index = 0;
                    index < pricesFeatures.prices.length;
                    index++
                  ) {
                    actives = 0
                    const obj = pricesFeatures.prices[index]
                    Object.entries(obj.features).forEach(([key, val]) => {
                      if (
                        selectedFilter[key] &&
                        selectedFilter[key][0] === val
                      ) {
                        actives++
                      }
                      if (actives === pricesFeatures.features.length) {
                        bestPrice = product.features_prices.prices[obj.price]
                        from = true
                      }
                    })
                  }
                }
              }
              if (filtroConfig.id === 'brand') {
                filtroValues.forEach((el) => {
                  if (el !== '') {
                    if (
                      store.state.brands.filter((b) => {
                        return b.id === el
                      })[0] !== undefined
                    ) {
                      selectedBrand.push(
                        store.state.brands.filter((b) => {
                          return b.id === el
                        })[0]
                      )
                    }
                  }
                })
              }
              filters[filtroConfig.id] = filtroValues
              if (filtroValuesSEO.length > 0) {
                filtersSEO[filtroConfig.id] = filtroValuesSEO
              }
            }
          })
        } catch (err) {
          return error({ statusCode: 404, message: err.message })
        }
      }

      // Ordenando filtros
      if (productType && productType.filters) {
        productType.filters.forEach((f) => {
          if (typeof filters[f.id] !== 'undefined') {
            if (!(f.id === 'store' && params.tienda && selectedStore.length === 0)) {
              filters2api += '/' + f.id + '-' + filters[f.id]
            }
          }
        })
      }
      // Order Filters
      const filtersInfo = `${$config.api}/app/v1/prices/list?_p=${country.id}&products_type=${product.type_id}&prices=1`
      const filtersInfo1 = $md5(filtersInfo)
      if (
        typeof store.state.modules[filtersInfo1] === 'undefined' ||
        Object.keys(store.state.modules[filtersInfo1]).length === 0
      ) {
        await store.dispatch('loadModules', filtersInfo)
      }
      const filtersInfo1List = store.state.modules[filtersInfo1]

      const stateFilters =
        store.state.filters.list.prices?.[productType.id]?.global
      if (stateFilters && filtersInfo1List) {
        filtersInfo1List.filters.url.forEach((filter) => {
          // Se recorre: store, brand, gender,...
          const stateFilter = stateFilters[filter.id]
          if (typeof stateFilter !== 'undefined' && filter.id !== 'brand') {
            const valConfig = []
            let valArr = Object.keys(stateFilter)
            if (filter.id === 'size') {
              valArr = valArr.sort((a, b) => a - b)
            }
            valArr.forEach((v) => {
              if (filter.values) {
                const valorConfig = filter.values.filter(
                  (val) => v.toString() === val.id.toString()
                )[0]
                valConfig.push(valorConfig.id)
              }
            })
            if (filter.id === 'size') {
              filtersSizeArray = valConfig
              filtersSize = valConfig.join(', ')
            }
          }
        })
      }

      // Dinamic SEO
      if (productType && productType.filters) {
        productType.filters.forEach((filter) => {
          if (filters[filter.id]) {
            if (selectedStore.length > 0) {
              filters.store = selectedStore
                .map(function (elem) {
                  return elem.nombre
                })
                .join(',')
            }
            if (selectedBrand.length > 0) {
              filters.brand = selectedBrand
                .map(function (elem) {
                  return elem.name
                })
                .join(',')
            }
          } else if (
            filter.default?.product_prices?.seo &&
            (!filter.default?.product_prices?.filters ||
              (filter.default?.product_prices.filters === 'no' &&
                Object.keys(filters).length === 0) ||
              (filter.default?.product_prices.filters === 'yes' &&
                Object.keys(filters).length > 0))
          ) {
            filtersSEO[filter.id] = filter.default.product_prices.seo
          }
          if (filters[filter.id]) {
            filtersSEO[filter.id] = (filter.seo || '{value}').replace(
              '{value}',
              (filtersSEO[filter.id] && filtersSEO[filter.id].join(', ')) ||
                filters[filter.id]
            )
          }
        })
      }
      const metasFilters =
        productType?.metas?.product_prices?.filters ||
        productType?.metas?.prices?.filters
      if (metasFilters) {
        Object.keys(metasFilters).forEach((filtersID) => {
          filters2seo[filtersID] = ''
          metasFilters[filtersID].forEach((filterID) => {
            const filter2seo = filtersSEO[filterID] || filters[filterID] || ''
            filters2seo[filtersID] += (filter2seo && ' ' + filter2seo) || ''
          })
          filters2seo[filtersID] = filters2seo[filtersID]
            .trim()
            .replace(/,/g, ', ')
            .replace(/ +/, ' ')
          filters2seo[filtersID] =
            filters2seo[filtersID] && ' ' + filters2seo[filtersID]
        })
      }

      // Ordenes
      let order = ''
      if (route.query.order) {
        const val = route.query.order.split('_')
        order = `&order=${val[0]}&direction=${val[1]}`
      }

      query = `${$config.api}/app/v1/prices/list?_p=${country.id}&products_type=${product.type_id}&products=${product.content_id}&filters=${filters2api}&page=${page}${order}&scope=precios_producto`
      code = $md5(query)
      if (
        typeof store.state.lists[code] === 'undefined' ||
        Object.keys(store.state.lists[code]).length === 0
      ) {
        await store.dispatch('loadLists', query)
      }

      const productPrices = store.state.lists[code]
      const gender = productPrices.filters.view.find(
        (element) => element.id === 'gender'
      )
      const activeGender = gender
        ? gender.values.filter((element) => element.active === 1)
        : []

      // filtros activos disponibles front
      const isActiveFilter = false
      const count = 0

      // calcular lowprice y highprice
      if (productPrices && productPrices.contents[0]) {
        productPrices.contents.forEach((p) => {
          if (typeof p.price !== 'undefined') {
            pPricesList.push((p.discount_price && p.discount_price.price) || p.price)
          }
        })
        if (pPricesList && pPricesList[0]) {
          minPrice = Math.min.apply(null, pPricesList)
          maxPrice = Math.max.apply(null, pPricesList)
        }
      }

      let structuredData = {}
      let structuredDataBreadCrumb = {}

      // calcular lowprice y highprice
      if (pPricesList && pPricesList[0]) {
        structuredData = {
          '@context': 'http://schema.org',
          '@type': 'Product',
          name: `${product.brand.name} ${product.name}`,
          offers: {
            type: 'AggregateOffer',
            lowPrice: `${minPrice}`,
            highPrice: `${maxPrice}`,
            priceCurrency: `${app.i18n.t('product_page.money_acronim')}`,
            offerCount: productPrices.total,
            availability: 'https://schema.org/InStock',
            itemCondition: 'https://schema.org/NewCondition',
            offers: []
          },
          review: [],
          image: `${product.photo.medium.src}`,
          brand: {
            type: 'brand',
            name: `${product.brand.name}`
          },
          description: `${app.i18n.t('product_prices.prices_of')} ${
            product.brand.name
          } ${product.name}${app.i18n.t('product_page.with_information')} ${
            productType.name
          }.`
        }

        if (product.opinions && product.opinions.total > 0) {
          structuredData.aggregateRating = {
            '@type': 'AggregateRating',
            bestRating: 5,
            ratingValue: product.opinions.score / 2,
            reviewCount: product.opinions.total
          }
        }

        product.opinions.contents.forEach((opinion) => {
          structuredData.review.push({
            type: 'Review',
            author: {
              type: 'Person',
              name: opinion.user.name
            },
            reviewBody: opinion.text,
            reviewRating: {
              type: 'Rating',
              ratingValue: opinion.score,
              bestRating: 10,
              worstRating: 1
            }
          })
        })

        productPrices.contents.forEach((price) => {
          if (price.type !== 'AdvertisingProductsCard') {
            structuredData.offers.offers.push({
              type: 'Offer',
              price: `${(price.discount_price && price.discount_price.price) || price.price}`,
              priceCurrency: `${app.i18n.t('product_page.money_acronim')}`,
              image: `${price.photo}`,
              availability: 'https://schema.org/InStock',
              itemCondition: 'https://schema.org/NewCondition'
            })
          }
        })

        structuredDataBreadCrumb = {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: []
        }
        if (breadcrumbData.length > 0) {
          breadcrumbData.forEach(function (element, index) {
            if (element.active) {
              structuredDataBreadCrumb.itemListElement.push({
                '@type': 'ListItem',
                position: index + 1,
                name: element.text
              })
            } else {
              structuredDataBreadCrumb.itemListElement.push({
                '@type': 'ListItem',
                position: index + 1,
                name: element.text,
                item: `${$config.domain}${element.href}`
              })
            }
          })
        }
      }

      // Metas, Head & Header
      let dinamicSeo = productType.metas.product_prices?.seo || ''
      dinamicSeo = textFunctions.textNormalize(
        dinamicSeo
          .replace('{product}', `${product.brand.name} ${product.name}`)
          .replace(/{filters:(.+?)}/g, function (_str, filterGroup) {
            return (
              (filters2seo[filterGroup] &&
                filters2seo[filterGroup].replace('-', ' ')) ||
              ''
            )
          })
      )
      // Dinamic SEO por genero
      let genderSeo = false
      Object.keys(filters || {}).forEach((filter) => {
        if ((filter === 'gender') && (filters[filter].length === 1)) {
          genderSeo = true
        } else if ((filter !== 'gender') && (filters[filter].length >= 0)) {
          genderSeo = false
          return false
        }
      })
      const header = {
        h1: dinamicSeo +
          (page > 1 ? ` - ${app.i18n.t('product_page.page')} ${page}` : ''),
        show:
          app.i18n.t('product_prices.prices') +
          ' ' +
          app.i18n.t('product_prices.of') +
          ' ' +
          productType.name.toLowerCase(),
        result:
          productPrices.total +
          ' ' +
          app.i18n.t('product_prices.prices').toLowerCase() +
          ' ' +
          app.i18n.t('product_prices.of') +
          ' ' +
          dinamicSeo
      }
      // Metas & Head
      let ampPath = route.path
      if (app.$isAMP) {
        ampPath = route.path.replace('/amp/', '/')
      }
      const canonical =
        metasLandingData.data.metas.canonical !== ''
          ? metasLandingData.data.metas.canonical.match(/^https?:\/\//)
            ? metasLandingData.data.metas.canonical
            : `${$config.domain}${metasLandingData.data.metas.canonical}`
          : false

      const title = `${
          app.i18n
                .t(!genderSeo ? 'product_prices.meta_title' : 'product_prices.meta_gender_title')
                .replace('{seo}', dinamicSeo)
                .replace('{pagina}', page > 1 ? ` | ${app.i18n.t('product_page.page')} ${page}` : '')
                .replace('{precio_desde}', `${app.i18n.t('product_page.price_from')} ${global.methods.formatPrice(product.price.lower?.custom ? product.price.lower.custom : product.price.real, country).replace(/\u00A0/g, ' ')}`)
                .replace('{fecha}', `${global.methods.capitalize(global.methods.formatDate(new Date(), { month: 'long' }))} ${new Date().getFullYear()}`)
        } | ${projectData.name}`

      const head = {
        title,
        meta: [
          {
            hid: 'title',
            name: 'title',
            content: title
          },
          {
            hid: 'description',
            name: 'description',
            content: app.i18n
              .t('product_prices.meta_description')
              .replace('{seo}', dinamicSeo)
              .replace(/{seo_the_best}/g, productType.texts?.the_best)
          },
          {
            property: 'og:url',
            content: `${$config.domain}${ampPath}`
          },
          {
            property: 'og:title',
            content: title
          },
          {
            property: 'og:description',
            content: app.i18n
              .t('product_prices.meta_description')
              .replace('{seo}', dinamicSeo)
              .replace(/{seo_the_best}/g, productType.texts?.the_best)
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: `${$config.locale}`
          }
        ],
        link: [],
        script: [
          { type: 'application/ld+json', json: structuredData },
          { type: 'application/ld+json', json: structuredDataBreadCrumb }
        ]
      }

      const path = route.path.split('/')
      let urlPaths = ''

      path.forEach(function (element, index) {
        if (index >= 7 && element !== '') {
          urlPaths += element + '/'
        }
      })

      if (canonical) {
        head.link.push({
          rel: 'canonical',
          href: (
            canonical +
            '/' +
            productType.paths.product_prices +
            '/' +
            productType.paths.products_all +
            '/' +
            urlPaths
          ).replace('//', '/')
        })
      } else {
        head.link.push({
          rel: 'canonical',
          href: genderSeo ? `${$config.domain}${ampPath}` : `${$config.domain}${global.methods.setProductLink(product.path)}`
        })
      }

      const urlIndexable = (Object.keys(route.query).length === 0) && filtersUrlIsIndexable(productPrices?.filters?.active, productType, 'product_prices')

      if (!urlIndexable) {
        head.meta.push({
          hid: 'robots',
          name: 'robots',
          content: 'noindex, follow'
        })
      }

      if (product?.photo?.medium?.src !== '') {
        head.meta.push({
          property: 'og:image',
          content: `${product.photo.medium.src}`
        })
      }

      if (!app.$isAMP && projectData.amp) {
        head.link.push({
          hid: 'amphtml',
          rel: 'amphtml',
          href: `${$config.domain}/${productType.paths.home}/${
            params.pathMatch
          }/amp/${productType.paths.product_prices}/${
            productType.paths.prices_all
          }/${params[1] ? params[1] : ''}`
        })
      }

      const extraModulesBottom = []
      if (
        productPrices.moduleText &&
        Object.keys(productPrices.moduleText).length > 0
      ) {
        const RewriteText = {
          template: productPrices.moduleText.ConfigurationFile.template,
          data: productPrices.moduleText.ConfigurationFile.content.info
        }
        extraModulesBottom.push(RewriteText)
      }

      productType.users = productType.users || projectData.users
      productType.services = productType.services || projectData.services

      const bestOffer = productPrices.contents[0]

      const zonesContent = ax.get()

      if (!app.$isAMP) {
        head.style = await cssFunctions.criticals(route.path, 'product_prices', {
          ABC_0: zonesContent.GlobalData?.zones?.ABC_0 || 0,
          score: product?.score ? 1 : 0,
          price: product?.prices.total ? 1 : 0
        })
      }
      return {
        iconClose: require('~/assets/img/icons/icon-close.svg'),
        breadcrumbData,
        product,
        bestOffer,
        prices: productPrices,
        testAB: productPrices.test,
        highlight: productPrices.highlight?.discount_price?.price
          ? productPrices.highlight.discount_price.price
          : productPrices.highlight.price,
        highlightStore: productPrices.highlight?.store,
        location,
        head,
        header,
        zones: zonesContent,
        filterActive,
        from,
        selectedFilter,
        bestPrice,
        order: route.query.order ? route.query.order : 'date_desc',
        selected: [],
        headerGallery: new Array(product.photo).concat(product.photos),
        sizeChart,
        bodyGallery: product.photos.filter((e) => e.large !== ''),
        isActiveFilter,
        count,
        selectedf: null,
        productType,
        activeGender,
        extraModulesBottom,
        urlIndexable,
        country,
        filtersSize,
        filtersSizeArray,
        pNews
      }
    } catch (err) {
      return error({ statusCode: 404, message: err.message })
    }
  },
  data () {
    return {
      bestOffer: false,
      order: null,
      paso: 1,
      slide: 0,
      slideSelected: 0,
      filterActiveClass: false,
      activeSection: 'prices',
      // imagenesu
      iconChevron: require('~/assets/img/icons/icon-chevron-down-grey.svg'),
      chevrondowngrey: {
        class: 'icon',
        alt: 'order',
        src: require('@/assets/img/icons/icon-chevron-down-grey.svg'),
        width: 10,
        height: 24
      },
      cerrar: {
        alt: 'cerrar',
        src: require('@/assets/img/icons/icon-close.svg'),
        width: 10,
        height: 10
      },
      atras: {
        alt: 'atras',
        src: require('@/assets/img/icons/icon-chevron-left.svg'),
        width: 10,
        height: 10
      },
      genreSelectPrices: null,
      sizeSelectPrices: null,
      genre: [
        { value: null, text: 'Género' },
        { value: 'woman', text: 'Mujer' },
        { value: 'man', text: 'Hombre' }
      ],
      size: [
        { value: null, text: 'Talla' },
        { value: '35', text: '35' },
        { value: '36', text: '36' }
      ],
      filters: []
    }
  },
  head () {
    return this.head
  },
  computed: {
    sagaProductText () {
      return this.$t('ProductHeaderButtons.version', { version: this.product.saga.product.title })
    }
  },
  beforeMount () {
    if (this.prices?.filters?.view) {
      this.filters = JSON.parse(JSON.stringify(this.prices.filters.view))

      this.filters.map((filter) => {
        const filterMapped = filter

        if (filterMapped.values) {
          filterMapped.values.map((value) => {
            const valueMapped = value

            valueMapped.finalUrl = this.create_filter_url(
              filter.id,
              value.id,
              this.prices.filters.url,
              this.productType,
              'product_prices',
              this.location,
              this.$route.query
            )

            const customFiltersActive = JSON.parse(JSON.stringify(this.prices.filters.active || {}))

            if (customFiltersActive[filter.id]) {
              Object.keys(customFiltersActive).forEach((filterID) => {
                if (filterID === filter.id) {
                  if (customFiltersActive[filterID].includes(value.id)) {
                    customFiltersActive[filterID] = customFiltersActive[filterID].filter((filterValue) => {
                      return filterValue !== value.id
                    })
                  } else {
                    customFiltersActive[filterID].push(value.id)
                  }
                }
              })
            } else {
              customFiltersActive[filter.id] = [value.id]
            }

            valueMapped.nofollow = (Object.keys(this.$route.query).length > 0) || !filtersUrlIsIndexable(customFiltersActive, this.productType, 'product_prices')

            return valueMapped
          })
        }

        return filterMapped
      })
    }
  },
  mounted () {
    customPageView.apply(this, [this.head.title])
    if (this.country.id !== projectData.id) {
      this.$gtm && this.$gtm.push({ event: 'IntView', country: this.country.iso, location: 'Page' })
    }
    const hash = this.$route?.hash ?? ''
    if (hash) {
      const container = document.querySelector(hash)
      if (container) {
        this.activeSection = hash.replace('#', '')
        container.scrollIntoView()
      }
    }
    this.$store.dispatch('setVersionsForChangeLang', this.product.versions)
  },
  beforeDestroy () {
    this.$store.dispatch('setVersionsForChangeLang', null)
  },
  methods: {
    onChange (val) {
      window.location =
        window.location.pathname + (val !== 'date_desc' ? '?order=' + val : '')
    },
    hideModal () {
      this.$refs['alert-modal'].hide()
    },
    selectSlide (index) {
      this.$refs.productCarousel.setSlide(index)
      this.slideSelected = index
    },
    onSlideEnd (slide) {
      this.slideSelected = slide
    },
    changeSection (refName) {
      const container = this.$el.querySelector(`#${refName}`)
      container.scrollIntoView()
    },
    playVideo (path) {
      this.$router.push({ path: this.setProductLink(path), hash: '#video' })
    }
  }
}
